import { Lightbulb } from 'lucide-react';

export default function ShortcutsInfo() {
  return (
    <section className="bg-white rounded-xl p-8 shadow-sm mt-16">
      <div className="flex items-center gap-4 mb-6">
        <Lightbulb className="w-8 h-8 text-[#6366F1]" />
        <h2 className="text-2xl font-semibold">What are Apple Shortcuts?</h2>
      </div>
      <div className="space-y-4 text-[#4B5563]">
        <p>
          Apple Shortcuts are powerful automation tools that help you streamline tasks on your iPhone, iPad, or Mac. They allow you to create custom workflows that can be triggered with a tap or voice command.
        </p>
        <p>
          With Shortcuts, you can enhance your productivity by:
        </p>
        <ul className="list-disc list-inside space-y-2 ml-4">
          <li>Automating repetitive tasks</li>
          <li>Combining multiple app actions into a single tap</li>
          <li>Creating custom voice commands for Siri</li>
          <li>Scheduling actions to run automatically</li>
        </ul>
        <p>
          To make your shortcuts easily accessible, you can add them to your Home Screen or Control Center. This allows you to run complex tasks with just a tap or swipe, saving you time and effort throughout your day.
        </p>
      </div>
    </section>
  );
}

