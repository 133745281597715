'use client'

import { ArrowRight } from 'lucide-react'

const examples = [
  {
    title: "Check RocketLab Stock Price",
    description: "Get the latest stock price for RocketLab.",
    tags: ["Finance", "Stocks"]
  },
  {
    title: "Should I Bring an Umbrella?",
    description: "Check the weather forecast and get a recommendation.",
    tags: ["Weather", "Recommendation"]
  },
  {
    title: "Calculate Tip",
    description: "Quickly calculate the tip for your meal.",
    tags: ["Finance", "Calculator"]
  },
  {
    title: "Exchange USD to Colombian Peso",
    description: "Get the latest exchange rate for USD to COP.",
    tags: ["Finance", "Currency"]
  }
];

export default function ExampleShortcuts({ onCreateShortcut }) {
  return (
    <div className="mt-16">
      <h2 className="text-2xl font-semibold text-center mb-8">Example Shortcuts</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {examples.map((example, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-[0_1px_3px_0_rgb(0,0,0,0.1)]">
            <h3 className="text-lg font-semibold mb-2">{example.title}</h3>
            <p className="text-[#6B7280] mb-4 text-sm">{example.description}</p>
            <div className="flex flex-wrap gap-2 mb-4">
              {example.tags.map((tag, tagIndex) => (
                <span 
                  key={tagIndex} 
                  className="px-3 py-1 bg-[#F3F4F6] text-[#374151] rounded-full text-sm"
                >
                  {tag}
                </span>
              ))}
            </div>
            <button 
              onClick={() => onCreateShortcut(example.description)}
              className="w-full border border-[#E5E7EB] text-[#374151] rounded-lg py-2 px-4 flex items-center justify-center gap-2 hover:bg-gray-50 transition-colors"
            >
              Create Shortcut
              <ArrowRight className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

